@use 'styles/includes' as *;

.CardNews {
    $root: &;

    padding: 2rem;
    height: 100%;
    position: relative;
    font-family: $font-family-regular;
    font-weight: $font-weight-bold;
    font-size: 1.4rem;
    line-height: 100%;
    color: $black;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    border-bottom: 1px solid $black;
    transition:
        background-color $transition-ease-out-expo,
        color $transition-ease-out-expo;

    @media (hover: hover) {
        &:hover {
            color: $white;
            background-color: $black;
        }
    }

    @include media(l) {
        padding: 4rem;
        min-height: 48rem;
        border-right: 0.1rem solid $black;
        border-bottom: 0.1rem solid $black;

        &:nth-child(1):not(.CardNews--Swiper, .CardNews--NoBorder) {
            border-top: 0.1rem solid $black;
            border-left: 0.1rem solid $black;
        }

        &:nth-child(2),
        &:nth-child(3) {
            border-top: 0.1rem solid $black;
        }

        &:nth-child(4n) {
            border-left: 0.1rem solid $black;
        }
        &:nth-child(7n),
        &:nth-child(10n) {
            border-left: 0.1rem solid $black;
        }
        &:nth-child(8n),
        &:nth-child(12n) {
            border-left: 0;
        }
    }

    &--NoBorder {
        border: 0;
    }

    &--Menu {
        padding: 2rem;
        min-height: auto;
        border: 0.1rem solid $black;
    }

    &--Swiper {
        border: 0;
        box-shadow: -0.1rem 0 0 0 $black;
    }

    &__Link {
        @extend %card-link;
    }

    &__Text {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 2rem;
    }

    &__Date,
    &__Type {
        font-weight: $font-weight-regular;
        text-transform: uppercase;
    }

    &__SubjectLink {
        font-weight: $font-weight-regular;
        text-transform: uppercase;
        position: relative;
        z-index: 1;

        &:hover {
            cursor: pointer;
        }
    }
    &__SubjectTitle {
        font-family: $font-family-semibold;
        font-weight: $font-weight-semibold;
    }

    &__SubjectTitle,
    &__Date {
        color: $grey-80;
        transition: color $transition-ease-out-expo;

        @media (hover: hover) {
            #{$root}:hover & {
                color: $grey-50;
            }
        }
    }

    &__Title {
        width: 100%;
        font-family: $font-family-bold;
        font-size: 3rem;
        line-height: 120%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow-wrap: anywhere;
        white-space: normal;

        #{$root}--Text & {
            -webkit-line-clamp: 3;
            line-clamp: 3;
        }

        #{$root}--Menu & {
            font-size: 2rem;
        }
    }

    &__Image {
        margin: 0 auto;
        width: 100%;
        max-width: 140px;
        aspect-ratio: 1/1;
        position: relative;
        border: 1px solid $black;

        @include media(m) {
            margin: 2rem auto;
            max-width: 176px;
        }

        #{$root}--Menu & {
            margin: 0 auto;
            max-width: 100px;
        }
    }

    &__Bottom {
        padding: 0.5rem 0;
        width: 100%;
        align-self: flex-end;
        display: flex;
        justify-content: space-between;

        #{$root}--Menu & {
            padding: 0;
        }
    }

    &__TopicsTitle {
        margin-bottom: 0.4rem;
        font-weight: $font-weight-regular;
    }

    &__TopicsText {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 30ch;
        line-height: 100%;
    }

    &__IconContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: auto;
    }

    &__Button {
        padding: 0.4rem 1rem;
        font-size: 1.4rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        text-transform: uppercase;
        border: 1px solid $black;
        border-radius: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
        transition: border-color $transition-ease-out-expo;

        @media (hover: hover) {
            #{$root}:hover & {
                border-color: $white;
            }
        }
    }

    &__Time {
        color: $grey-50;
        text-transform: none;
    }
}
